@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 432px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}


/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Work Sans', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

*:focus {
  box-shadow: none !important;
}

.pro-sidebar {
  color: #1d1d1d;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.pro-sidebar>.pro-sidebar-inner {
  background: white;
  height: 100%;
  position: relative;
  z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>img.sidebar-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  opacity: 0.3;
  left: 0;
  top: 0;
  z-index: 100;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
  flex-grow: 1;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
  border-top: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pro-sidebar .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: none;
}

.pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

.pro-sidebar.rtl {
  text-align: right;
  direction: rtl;
}

@media (max-width: 480px) {
  .pro-sidebar.xs {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.xs.collapsed {
    left: -80px;
  }

  .pro-sidebar.xs.toggled {
    left: 0;
  }

  .pro-sidebar.xs.toggled .overlay {
    display: block;
  }

  .pro-sidebar.xs.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.xs.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.xs.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 576px) {
  .pro-sidebar.sm {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.sm.collapsed {
    left: -80px;
  }

  .pro-sidebar.sm.toggled {
    left: 0;
  }

  .pro-sidebar.sm.toggled .overlay {
    display: block;
  }

  .pro-sidebar.sm.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.sm.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.sm.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 768px) {
  .pro-sidebar.md {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.md.collapsed {
    left: -80px;
  }

  .pro-sidebar.md.toggled {
    left: 0;
  }

  .pro-sidebar.md.toggled .overlay {
    display: block;
  }

  .pro-sidebar.md.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.md.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.md.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 992px) {
  .pro-sidebar.lg {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.lg.collapsed {
    left: -80px;
  }

  .pro-sidebar.lg.toggled {
    left: 0;
  }

  .pro-sidebar.lg.toggled .overlay {
    display: block;
  }

  .pro-sidebar.lg.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.lg.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.lg.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1200px) {
  .pro-sidebar.xl {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.xl.collapsed {
    left: -80px;
  }

  .pro-sidebar.xl.toggled {
    left: 0;
  }

  .pro-sidebar.xl.toggled .overlay {
    display: block;
  }

  .pro-sidebar.xl.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.xl.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.xl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1600px) {
  .pro-sidebar.xxl {
    position: fixed;
    left: -270px;
  }

  .pro-sidebar.xxl.collapsed {
    left: -80px;
  }

  .pro-sidebar.xxl.toggled {
    left: 0;
  }

  .pro-sidebar.xxl.toggled .overlay {
    display: block;
  }

  .pro-sidebar.xxl.rtl {
    left: auto;
    right: -270px;
  }

  .pro-sidebar.xxl.rtl.collapsed {
    left: auto;
    right: -80px;
  }

  .pro-sidebar.xxl.rtl.toggled {
    left: auto;
    right: 0;
  }
}

.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-menu-item .pro-inner-item:before {
  content: '';
  display: inline-block;
  width: 5px;
  min-width: 5px;
  height: 5px;
  /* border: 1px solid white; */
  background-color: #979797;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
}

.pro-sidebar .pro-menu.submenu-bullets .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-menu-item.active .pro-inner-item:before {
  background-color: #1d1d1d;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pro-sidebar .pro-menu {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  position: relative;
  background-color: white;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #adadad;
}

.pro-sidebar .pro-menu a:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

.pro-sidebar .pro-menu a:hover {
  color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #1d1d1d;
}

.react-slidedown.pro-inner-list-item .pro-menu-item {
  color: #979797;
  padding-bottom: 10px;
}

.react-slidedown.pro-inner-list-item .pro-menu-item:active {
  color: #1d1d1d !important;
}

.pro-sidebar .pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.pro-sub-menu.open>div.pro-inner-item {
  background: #f7f9fa;
}

.pro-sidebar.collapsed .pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.pro-sub-menu.open>div.pro-inner-item {
  background: transparent;
}

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
  display: flex;
  margin-right: 5px;
  opacity: 1;
  transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 35px 8px 20px;
  cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
  outline: none;
  color: #1d1d1d;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  margin-right: 10px;
  font-size: 14px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
}

.pro-sidebar.collapsed .pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.active>div>.pro-icon-wrapper {
  background-color: #eef0f3 !important;
}

.pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.active>div>span.pro-icon-wrapper {}

.pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-icon-wrapper {
  background-color: #eef0f3 !important;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  color: #1d1d1d;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item:before {
  background: #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  display: inline-block;
  border-style: solid;
  border-color: #adadad;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  vertical-align: middle;
  transition: transform 0.3s;
  transform: rotate(-45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item:before {
  background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 24px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
  background: none;
  width: auto;
  min-width: auto;
  height: auto;
  line-height: auto;
}

.pro-sidebar .pro-menu:not(.inner-submenu-arrows) .pro-inner-list-item .pro-menu-item.pro-sub-menu .pro-inner-item .pro-arrow-wrapper {
  display: none;
}

/* .pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: white;
} */

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item:active>.pro-icon-wrapper {
  background-color: #eef0f3;
}

.pro-sidebar .pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.active {
  background: #f7f9fa;
}

.pro-sidebar .pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item:hover {
  background: #f7f9fa;
}

.pro-sidebar.collapsed .pro-sidebar-inner>div>div.pro-sidebar-content>nav>ul>li.pro-menu-item.active {
  background: transparent !important;
}

.pro-sidebar .pro-menu.square .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  border-radius: 0;
}

.pro-sidebar .pro-menu.round .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  border-radius: 4px;
}

.pro-sidebar .pro-menu.circle .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  border-radius: 50%;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item {
  position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.suffix-wrapper,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.prefix-wrapper {
  opacity: 0;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-list-item {
  background-color: white;
  z-index: 111;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu {
  position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item {
  pointer-events: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  display: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
  height: auto !important;
  position: fixed;
  visibility: hidden;
  min-width: 220px;
  max-width: 270px;
  background-color: transparent;
  max-height: 100%;
  padding-left: 3px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
  padding-left: 10px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  max-height: 100vh;
  overflow-y: auto;
  background-color: white;
  padding-left: 20px;
  border-radius: 4px;
  color: #979797;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item {
  transition: visibility, transform 0.3s;
  visibility: visible;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
  margin-right: 0;
  margin-left: 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item {
  padding: 8px 20px 8px 35px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  margin-right: 0;
  margin-left: 10px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  right: auto;
  left: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(135deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  transform: rotate(45deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0;
  padding-right: 24px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 15px 8px 30px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  margin-right: 0;
  margin-left: 15px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
  padding-left: 0;
  padding-right: 3px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
  padding-right: 10px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
  padding-left: 0;
  padding-right: 20px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 5px 8px 30px;
}

.popper-arrow {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^='left']>.popper-arrow {
  right: 0;
  border-right: 7px solid white;
}

.popper-element[data-popper-placement^='right']>.popper-arrow {
  left: 0;
  border-left: 7px solid white;
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
  overflow-y: hidden;
}

.react-slidedown.closed {
  display: none;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}

.shake-vertical {
  -webkit-animation: shake-vertical 6s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite reverse both;
  animation: shake-vertical 6s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite reverse both;
}